const headerBg = () => {
    const header = document.querySelector('.header'),
          headerHeight = header.style.height;
    

    document.addEventListener('scroll', () => {
        const windowScroll = window.scrollY || document.documentElement.scrollTop;
        if (window.innerWidth > 640 && windowScroll > headerHeight) {
            header.style.background = `linear-gradient(rgba(28, 24, 53, .8) 90%, transparent 100%)`; 
        } else {
            header.style.background = 'initial'; 
        }
    });

};

export default headerBg;

