const gallerySlider = () => {

  $(document).ready(function(){
    $('.gallery__list').slick({
      
      infinite: true,
      slidesToShow: 3,
      swipeToSlide: true,
      touchMove: true,
      focusOnSelect: true,
      responsive: [
        {
          breakpoint: 1180,
          settings: {
            infinite: true,
            slidesToShow: 3,
            variableWidth: true,
            swipeToSlide: true,
            touchMove: true,
            focusOnSelect: true,
            arrows: false,
            centerMode: true,
            centerPadding: '0'
          }
        }]

    });
  }); 

};

export default gallerySlider;

